import React, { createContext, useContext, useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

export type QRCodeDispatchT = {setIsModalOpen: (showModal: boolean) => void}
type QRCodeModalProviderProps = {
  children: React.ReactNode;
  title: string;
  instructions: string;
  qrCode: string;
}

const QRCodeModalContext = createContext<QRCodeDispatchT | undefined>(undefined)

const QRCodeModalProvider = ({ children, title, instructions, qrCode }: QRCodeModalProviderProps) => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const domReady = useDomReady()

  const value = {
    setIsModalOpen: (showModal: boolean) => setIsModalOpen(showModal),
  }

  const showModal = (domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isModalOpen}
        setIsModal={setIsModalOpen}
        title={title}
        instructions={instructions}
        qrCode={qrCode}
      />
    </Modal>
  ))
  return (
    <QRCodeModalContext.Provider value={value}>
      {showModal}
      {children}
    </QRCodeModalContext.Provider>
  )
}

export const useQRCodeModal = () => {
  const context = useContext(QRCodeModalContext)
  if (context === undefined) {
    throw new Error('useQRCodeModal must be used within a QRCodeModalProvider')
  }
  return context
}

export default QRCodeModalProvider
